export const tratamente = [
  {name: 'Reevaluare si igienizare parodontala', pret: '700'},
  {name: 'Evaluare Parodontala ', pret: '360'},
  {name: 'Tratament parodontal chirurigical', pret: '400'},
  {name: 'Tratament parodontal non-chirugical', pret: '190'},
  {name: 'Tratament parodontal non-chirugical laser', pret: '250'},
  {name: 'Consultatie', pret: '250'},
  {name: 'Consultație + Documentare clinică completă', pret: '350'},
  {name: 'Smile design + plan tratament individualizat', pret: '650'},
  {name: 'Pachet consultatie estetica', pret: '750'},
  {name: 'Pachet consultatie implant', pret: '850'},
  {name: 'Pachet consultatie reabilitate completa', pret: '1200'},
  {name: 'One day Full Experience', pret: '1500'},
  {name: 'Plan de tratament', pret: '500'},
  {name: 'Control anual', pret: '0' },
  {name: 'Control periodic', pret: '200'},
  {name: 'Radiografie retroalvolara digitala', pret: '80'},
  {name: 'Radiografie panoramica digitala OPG', pret: '125'},
  {name: '3D CT Dentar partial', pret: '400'},
  {name: '3D CT Dentar total', pret: '500'},
  {name: 'Igienizare profesionala GBT', pret: '500'},
  {name: 'Igienizare profesionala subgingivala GBT', pret: '700'},
  {name: 'Igienizare profesionala GBT - 6 luni', pret: '400'},
  {name: 'Obturatie dinte lateral  Waterlase', pret: '600'},
  {name: 'Obturatie dinte frontal  Waterlase', pret: '700'},
  {name: 'Gingivectomie laser WaterLase', pret: '500'},
  {name: 'Alungire coronara laser WaterLase', pret: '800'},
  {name: 'Alungire coronara flapless', pret: '800'},
  {name: 'Frenectomie laser WaterLase', pret: '950'},
  {name: 'Incizie & drenaj abces  WaterLase', pret: '500'},
  {name: 'Descoperire implant  WaterLase', pret: '500'},
  {name: 'Mini implant ortodontic', pret: '1000'},
  {name: 'Obturatie colet cls.V', pret: '400'},
  {name: 'Obturatie dinte frontal cls.III', pret: '400'},
  {name: 'Obturatie dinte frontal cls.IV', pret: '500'},
  {name: 'Obturatie dinte frontal cls.IV + incizal', pret: '600'},
  {name: 'Obturatie dinte frontal complexa', pret: '700'},
  {name: 'Obturatie estetica frontala vestibulara', pret: '550'},
  {name: 'Obturatie dinte posterior cls.I', pret: '400'},
  {name: 'Obturatie dinte posterior cls.II-a cu 1 suprafata', pret: '450'},
  {name: 'Obturatie dinte posterior cls.II-a cu 2 suprafate', pret: '500'},
  {name: 'Obturatie dinte posterior cls.II-a cu 3 suprafate', pret: '650'},
  {name: 'Obturatie dinte posterior cls.II-a cu 4 suprafate', pret: '850'},
  {name: 'Obturatie sub microscop', pret: '750'},
  {name: 'Pansament calmant  urgenta', pret: '300'},
  {name: 'Obturatie provizorie', pret: '100'},
  {name: 'Model studiu + plan tratament', pret: '400'},
  {name: 'Tratament ortodontic Aparat dentar fix metalic', pret: '4500'},
  {name: 'Tratament ortodontic Aparat dentar fix estetic', pret: '5990'},
  {name: 'Tratament ortodontic Aparat dentar mobil', pret: '2000'},
  {name: 'Tratament ortodontic CLEAR CORRECT UNLIMITED (full)', pret: '27000'},
  {name: 'Tratament ortodontic CLEAR CORRECT TWO', pret: '21000'},
  {name: 'Tratament ortodontic CLEAR CORRECT ONE', pret: '18000'},
  {name: 'Tratament ortodontic CLEAR CORRECT MINI', pret: '14000'},
  {name: 'Tratament ortodontic CLEAR ALIGNER', pret: '10000'},
  {name: 'Tratament ortodontic CLEAR ALIGNER 2', pret: '15000'},
  {name: 'Tratament ortodontic SPARK10 SINGLE', pret: '10000'},
  {name: 'Tratament ortodontic SPARK10 DOUBLE', pret: '13000'},
  {name: 'Tratament ortodontic SPARK20 SINGLE', pret: '14000'},
  {name: 'Tratament ortodontic SPARK20 DOUBLE', pret: '17000'},
  {name: 'Tratament ortodontic SPARK FULL SINGLE', pret: '21000'},
  {name: 'Tratament ortodontic SPARK FULL DOUBLE', pret: '26000'},
  {name: 'Control + activare aparat', pret: '200'},
  {name: 'Control + activare aparat 2 arcade', pret: '250'},
  {name: 'Bracket metalic desprins', pret: '300'},
  {name: 'Bracket estetic desprins', pret: '350'},
  {name: 'Expansor maxilar', pret: '2500'},
  {name: 'Trainer silicon', pret: '2000'},
  {name: 'Mentinator spatiu', pret: '1300'},
  {name: 'Tratament ortodontic gutiere Clear Aligner', pret: '12500'},
  {name: 'Indepartare aparat ortodontic fix / arcada', pret: '300'},
  {name: 'Indepartare aparat ortodontic fix / ambele arcade', pret: '500'},
  {name: 'Albire dentara profesionala', pret: '1500'},
  {name: 'Albire dentara laser', pret: '1800'},
  {name: 'Amprenta digitala 3D', pret: '400'},
  {name: 'Digital Smile Design / dinte', pret: '250'},
  {
    name: 'Fateta ceramica premium feldspatica',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.2,
    pret: '3500'
  },
  {
    name: 'Fateta ceramica premium feldspatica NONPREP',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.2,
    pret: '3750'
  },
  {
    name: 'Fateta ceramica premium Ambermill',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.2,
    pret: '3000'
  },
  {
    name: 'Fateta ceramica CEREC',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.2,
    pret: '2800'
  },
  {name: 'Coroana ceramica premium felspatica', pret: '3500'},
  {
    name: 'Coroana ceramica premium feldspatica NONPREP',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.2,
    pret: '3750'
  },
  {
    name: 'Coroana ceramica premium Ammbermill',
    dinteDD: true,
    id: 1.1,
    pret: '3000'
  },
  {name: 'Coroana ceramica suport metal', pret: '1800'},
  {
    name: 'Inlay/onlay ceramica premium CEREC',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.3,
    pret: '2250'
  },
  {
    name: 'Coroana provizorie rapidă cabinet',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '250'
  },
  {
    name: 'Coroana provizorie laborator Telio',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '500'
  },
  {
    name: 'Coroana PMMA',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '900'
  },
  {
    name: 'Coroana PMMA implant',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '1200'
  },
  {
    name: 'Coroana compozit frezat Ivoclar',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '1400'
  },
  {name: 'Wax-up / dinte', pret: '250'},
  {name: 'Bont protetic custom zirconiu / implant Megagen', pret: '1600'},
  {name: 'Bont protetic custom zirconiu / implant Straumann', pret: '1750'},
  {name: 'Bont protetic std titan / implant Megagen', pret: '1200'},
  {name: 'Bont protetic std titan / implant Straumann', pret: '1300'},
  {
    name: 'Lucrare protetica finala ALL ON 4/6 metalo-ceramica',
    dinteDD: true,
    id: 1.5,
    pret: '19000'
  },
  {
    name: 'Lucrare protetica finala ALL ON 4/6 premium zirconiu + titan',
    dinteDD: true,
    id: 1.5,
    pret: '37500'
  },
  {
    name: 'Lucrare protetica finala premium ALL ON 4/6 full zirconiu',
    dinteDD: true,
    id: 1.1,
    pret: '32500'
  },
  {name: 'Reconstructie bonturi cu pivot fibra sticla', pret: '500'},
  {name: 'Reconstructie bonturi cu fibra sticla', pret: '500'},
  {name: 'Bonturi fixare multi-unit ALL on 4/6', pret: '850'},
  {name: 'Ablatie coroana zirconiu/emax', pret: '300'},
  {name: 'Ablatie coroana metalo-ceramica', pret: '250'},
  {name: 'Ablatie laser fateta/coroana zirconiu/emax', pret: '350'},
  {name: 'Proteza Kemeny', pret: '1000'},
  {name: 'Gutiera contentie premium bruxism', pret: '700'},
  {name: 'Gutiera contentie premium', pret: '600'},
  {name: 'Proteza totala premium', pret: '2700'},
  {name: 'Proteza partiala premium', pret: '2400'},
  {name: 'Proteza scheletata', pret: '6000'},
  {name: 'Cimentare', pret: '200'},
  {name: 'Coroana ceramica cerec', dinteDD: true, id: 1.1, pret: '2500'},
  {name: 'Endocrown cerec - coroana+pivot', dinteDD: true, id: 1.1, pret: '2800'},
  {name: 'Inlay/onlay ceramica cerec', pret: '2250'},
  {name: 'Tratament endo microscop - vital  incisiv', pret: '850'},
  {name: 'Tratament endo microscop - vital  premolar', pret: '1100'},
  {name: 'Tratament endo microscop - vital  molar', pret: '1350'},
  {name: 'Retratament endo microscop - devital  incisiv', pret: '950'},
  {name: 'Retratament endo microscop - devital  premolar', pret: '1250'},
  {name: 'Retratament endo microscop - devital  molar', pret: '1500'},
  {name: 'Indepartare instrument fracturat', pret: '350'},
  {name: 'Ablatie Dentatus / DCR', pret: '250'},
  {name: 'Aplicare MTA', pret: '300'},
  {
    name: 'Implant dentar Megagen Anyone / MIS Seven ',
    dinteDD: true,
    id: 4,
    pret: '3000'
  },
  {
    name: 'Implant dentar JD Dentalcare',
    dinteDD: true,
    id: 4,
    pret: '3000'
  },
  {
    name: 'Implant dentar premium Megagen Anyridge',
    dinteDD: true,
    id: 4,
    pret: '4800'
  },
  {
    name: 'Implant dentar premium Neodent',
    dinteDD: true,
    id: 4,
    pret: '4500'
  },
  {
    name: 'Implant dentar Straumann BLX SLA Active',
    dinteDD: true,
    id: 4,
    pret: '5900'
  },
  {
    name: 'One day implant Megagen Anyone / JD Dentalcare',
    dinteDD: true,
    id: 4,
    pret: '4600'
  },
  {
    name: 'One day implant premium Neodent',
    dinteDD: true,
    id: 4,
    pret: '5500'
  },
  {
    name: 'One day implant Strauman BLX',
    dinteDD: true,
    id: 4,
    pret: '7000'
  },
  {
    name: 'One day implant premium Megagen Anyridge',
    dinteDD: true,
    id: 4,
    pret: '5800'
  },
  {name: 'ALL on 4 - 4 implanturi dentare Megagen Anyone / MIS Seven / JD Dentalcare', pret: '19000'},
  {name: 'ALL on 4 - 4 implanturi dentare premium Megagen Anyridge / Neodent', pret: '23000'},
  {name: 'ALL on 4 - 4 implanturi dentare Straumann BLX', pret: '25500'},
  {name: 'ALL on 6 - 6 implanturi dentare Megagen Anyone / MIS Seven / JD Dentalcare', pret: '21000'},
  {name: 'ALL on 6 - 6 implanturi dentare premium Megagen Anyridge', pret: '25000'},
  {name: 'ALL on 6 - 6 implanturi dentare Straumann BLX', pret: '28000'},
  {name: 'Starconcept - 4 implanturi dentare premium Megagen Anyridge', pret: '32000'},
  {name: 'Starconcept - 6 implanturi dentare premium Megagen Anyridge', pret: '36000'},
  {name: 'Starconcept - 8 implanturi dentare premium Megagen Anyridge', pret: '38000'},
  {name: 'Starconcept - 4 implanturi dentare Straumann BLX SLA Active', pret: '34000'},
  {name: 'Starconcept - 6 implanturi dentare Straumann BLX SLA Active', pret: '40000'},
  {name: 'Starconcept - 8 implanturi dentare Straumann BLX SLA Active', pret: '43000'},
  {name: 'Ghid chirurgical implant', pret: '800'},
  {name: 'Ghid chirurgical implant 2-4 gauri', pret: '1200'},
  {name: 'Ghid chirurgical implant arcada', pret: '1600'},
  {name: 'Socket Shield', pret: '3000'},
  {name: 'Aditie osoasa 1-2 dinti', pret: '2500'},
  {name: 'Aditie osoasa extinsă', pret: '5000'},
  {name: 'Aditie osoasa complexă', pret: '7500'},
  {name: 'Aditie osoasa PLATE', pret: '9000'},
  {name: 'Sinus lift extern', pret: '7700'},
  {name: 'Sinus lift intern', pret: '3300'},
  {name: 'Grefa Gingie extinsa', pret: '4000'},
  {name: 'Grefa Gingie libera FGG', pret: '3600'},
  {name: 'Grefa Gingie 2/3 dinti', pret: '3500'},
  {name: 'Grefa gingie 1 dinte', pret: '3000'},
  {name: 'Anestezie digitala STA', pret: '150'},
  {name: 'Extractie dinte monoradicular', dinteDD: true, id: 3, pret: '400'},
  {name: 'Extractie dinte pluriradicular', dinteDD: true, id: 3, pret: '450'},
  {name: 'Extractie rest radicular', pret: '500'},
  {name: 'Extractie rest radicular + chiuretaj alveolar', pret: '700'},
  {name: 'Extractie molar minte erupt complet', pret: '660'},
  {name: 'Extractie molar minte erupt partial', pret: '900'},
  {name: 'Extractie molar minte inclus', pret: '1250'},
  {name: 'Investigatie exploratorie endodontica cu microscop Zeiss ', pret: '250'},
  {name: 'Incizie / drenaj laser abces dentar  WaterLase', pret: '500'},
  {name: '[K] Consultatie complexa medic specialist pedodont copii sub 3ani', pret: '180'},
  {name: '[K] Consultatie complexa medic specialist pedodont', pret: '200'},
  {name: '[K] Consultatie complexa medic specialist pedodont + periaj profesional', pret: '250'},
  {name: '[K] Control periodic + periaj profesional', pret: '170'},
  {name: '[K] Conisliere psihologica părinți și copii / ora', pret: '150'},
  {name: '[K] Tratament de urgenta dentitie temporara', pret: '270'},
  {name: '[K] Tratament de urgenta dentitie permanenta', pret: '390'},
  {name: '[K] Sedinta acomodare pacient necooperant', pret: '150'},
  {name: '[K] DT - Obturatie ciment glassionomer 1 suprafata', pret: '230'},
  {name: '[K] DT - Obturatie ciment glassionomer 2 suprafete', pret: '270'},
  {name: '[K] DT - Obturatie ciment glassionomer 3 suprafete', pret: '300'},
  {name: '[K] DT - Obturatie compozit 1 suprafata', pret: '230'},
  {name: '[K] DT - Obturatie compozit 2 suprafete', pret: '270'},
  {name: '[K] DT - Obturatie compozit 3 suprafete', pret: '300'},
  {name: '[K] DT - Obturatie compozit colorat', pret: '220'},
  {name: '[K] DT - Obturatie provizorie', pret: '130'},
  {name: '[K] DT - Baza obturatie', pret: '100'},
  {name: '[K] DT - Aplicare SDF', pret: '120'},
  {name: '[K] DP - Obturatie preventiva dinte permanent', pret: '230'},
  {name: '[K] DP - Coafaj', pret: '180'},
  {name: '[K] DP - Coafaj cu Biodentine', pret: '250'},
  {name: '[K] DP - Baza obturatie', pret: '130'},
  {name: '[K] DP - Obturatie ciment gassionomer 1 suprafata', pret: '250'},
  {name: '[K] DP - Obturatie ciment gassionomer 2 suprafete ', pret: '300'},
  {name: '[K] DP - Obturatie ciment gassionomer x3 suprafete ', pret: '350'},
  {name: '[K] DP - Obturatie compozit 1 suprafata', pret: '250'},
  {name: '[K] DP - Obturatie compozit 2 suprafete ', pret: '300'},
  {name: '[K] DP - Obturatie compozit 3 suprafete', pret: '350'},
  {name: '[K] DP - Obturatie provizorie', pret: '130'},
  {name: '[K] DP - Sigilare dinte permanent', pret: '200'},
  {name: '[K] DT - Pulpotomie vitala', pret: '200'},
  {name: '[K] DT - Tratament de canal dinte monoradicular', pret: '220'},
  {name: '[K] DT - Tratament de canal dinte pluriradicular', pret: '270'},
  {name: '[K] DT - Tratament devitalizant', pret: '170'},
  {name: '[K] DT - Pansament calmant + obturatie provizorie', pret: '150'},
  {name: '[K] DT - Tratament medicamentos/sedinta + obturatie provizorie', pret: '170'},
  {name: '[K] DT - Trarament necroza / gangrena pulpara', pret: '200'},
  {name: '[K] DT - Sigilare dinte temporar', pret: '170'},
  {name: '[K] DT - Obturatie de canal dinte pluriradicular', pret: '180'},
  {name: '[K] DT - Refacere obturație în maxim 90 de zile ', pret: '0'},
  {name: '[K] DT - Refacere obturație ', pret: '150'},
  {name: '[K] DT - Drenaj ', pret: '150'},
  {name: '[K] DT - Tratament cu Biodentine ', pret: '250'},
  {name: '[K] DT - Tratament minim invaziv ICON / dinte', pret: '300'},
  {name: '[K] DT - Reconstructie cu capa de celuliod ', pret: '250'},
  {
    name: '[K] DT - Extractie dinte mobil',
    dinteDD: true,
    id: 3,
    pret: '170'
  },
  {
    name: '[K] DT Extractie dinte temporar fara mobilitate',
    dinteDD: true,
    id: 3,
    pret: '230'
  },
  {name: '[K] DT - Extractie rest radicular ', pret: '230'},
  {name: '[K] DT - Extractie in scop ortodontic ', pret: '230'},
  {name: '[K] DT - Curatare cavitate laser ', pret: '300'},
  {name: '[K] DT - Gingivectomie laser ', pret: '400'},
  {name: '[K] DT - Decapusonare laser ', pret: '450'},
  {name: '[K] DT - Frenectomie laser ', pret: '900'},
  {
    name: '[K] DT - Extractie dinte in scop ortodontic',
    dinteDD: true,
    id: 3,
    pret: '230'
  },
  {
    name: '[K] DT - Imobilizare dinte post traumatism',
    dinteDD: true,
    id: 3,
    pret: '350'
  },

  {
    name: '[K] DT - Coroana metalica',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '500'
  },
  {name: '[K] DT - Coroana Zirconiu', dinteDD: true, id: 1.1, pret: '600'},
  {name: '[K] Mentinator de spatiu metalic', pret: '750'},
  {name: '[K] Imobilizare dinte post traumatic', pret: '350'},
  {
    name: '[K] Model de studiu',
    dinteDD: true,
    id: 1.1,
    pret: '150'
  },

  {name: '[K] DT Periaj profesional + flourizare', pret: '230'},
  {name: '[K] DT Periaj profesional', pret: '150'},
  {name: '[K] DT Pachet igienizare', pret: '300'},
  {name: '[K] DM Pachet igienizare ', pret: '350'},
  {name: '[K] DP Pachet igienizare', pret: '400'},
  {name: '[K] Flouorizare topica', pret: '150'},
  {name: '[K] Sigilare dinte temporar', pret: '180'},
  {name: '[K] Obturatie preventiva dinte temporar', pret: '200'},
  {name: '[K] Sigilare dinte permanent', pret: '220'},
  {name: '[K] Obturatie preventiva dinte permanent', pret: '250'},
  {name: '[K] Refacere sigilare', pret: '130'},
  {name: '[K] Determinare indice de placa', pret: '70'},
  {name: '[K] Tratament de urgenta', pret: '270'},
  {
    name: 'Coroana metalo-ceramica pe implant',
    dinteDD: true,
    id: 1.5,
    pret: '2200'
  },
  {
    name: 'Punte Zirconiu',
    fill: '#34a186',
    dinteDD: true,
    id: 6.1,
    pret: '3000'
  },
  {
    name: 'Coroana PMMA lungă durata',
    fill: '#34a186',
    dinteDD: true,
    id: 6.1,
    pret: '900'
  },
  {
    name: 'Punte acrilica provizorie',
    fill: '#a9e8e6',
    dinteDD: true,
    id: 6.4
  },
  {
    name: 'Punte metalo-ceramica',
    fill: '#FFB400',
    dinteDD: true,
    id: 6.5
  },
  {name: 'Indepartare expansor', pret: '250'},
  {name: 'Tratament de urgenta', pret: '500'},
  {name: 'Activare aparat estetic', pret: '250'},
  {name: 'Activare aparat mobil', pret: '150'},
  {name: 'Rezectie apicala', pret: '3000'},
  {name: 'Extractie cu split paro/dinte', pret: '1000'},
  {name: 'Chiuretaj postextractional laser/dinte', pret: '500'},
  {name: 'Igienizare lucrări protetice', pret: '500'},
  {name: 'Extractie implant osteointegrat', pret: '3000'},
  {name: 'Extracție implant cu periimmplantita', pret: '2200'},
  {name: 'Regularizare creasta osoasa', pret: '2500'},
  {name: 'Retainer post tratament ortodontic', pret: '600'},
  {name: 'Refacere aparat dentar mobil', pret: '300'},
  {name: 'Adaugare brackets/dinte', pret: '200'},
  {name: 'Disjunctor/Distalizator hibrid BENEFIT', pret: '4400'},
  {name: 'Aparat de contentie mobil', pret: '800'},
  {name: 'Disjunctor pe miniimplante MSE', pret: '3700'}
];
